import * as React from "react";
import "../../gatsby-browser.js";
import Navbar from "../component/Navbar/Navbar";
import Footer from "../component/Footer/Footer";
import { motion } from "framer-motion";
import { lan } from "../lan/service";
import Services from "../static/img/img/work.png";
// import { lan_data } from "../component/Local/local";
import Illustration from "../static/img/mobileDevelopment/illustration.svg";
import Apple from "../static/img/mobileDevelopment/apple-icon.svg"
import Swift from "../static/img/mobileDevelopment/swift.svg"
import Kotlin from "../static/img/mobileDevelopment/kotlin.svg"
import Android from "../static/img/mobileDevelopment/android.svg"
import Flutter from "../static/img/webDevelopment/flutter-icon.svg"
import ReactNative from "../static/img/webDevelopment/react-icon.svg"
import MetaTags from "../component/MetaTags.jsx";
class MobileDevelopment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      loading: true,
      name: [],
      email: [],
      phone: [],
      message: [],
    };
  }

  getName = (e) => {
    this.setState({
      name: e.target.value,
    });
  };

  getEmail = (e) => {
    this.setState({
      email: e.target.value,
    });
  };

  getPhone = (e) => {
    this.setState({
      phone: e.target.value,
    });
  };

  getMessage = (e) => {
    this.setState({
      message: e.target.value,
    });
  };

  render() {
    const pageVariants = {
      initial: {
        opacity: 0,
      },
      in: {
        opacity: 1,
      },
      out: {
        opacity: 0,
      },
    };

    const { data, loading } = this.state;

    return (
      <>
        <Navbar />
        <motion.div
          initial="initial"
          animate="in"
          exit="out"
          variants={pageVariants}
        >
          <section id="mobile-tech-banner" className="">
            <div className="banner-text">
              <h1 className="highlighted-text">
                Make with our cutting-edge mobile application development
              </h1>
              <p>{lan.en["mobile"]["head"][0]}</p>
            </div>
         

            <img  src={Illustration} alt="" />
            
          </section>

          <div className="container">
            <div className="row about-extra">
              <div className="mobile-desc" align={"justify"}>
                <h2 align={"center"} className="highlighted-text">
                  {" "}
                  About <span className="light-text"> Mobile Tech</span>{" "}
                </h2>

                <p className={"service_about"}>
                  {lan.en["mobile"]["about"][0]}
                </p>
              </div>
            </div>
          </div>

          <section id="services" className="advance">
            <header className="section-header">
              <h3 className="light-text"> End-to-End Mobile Development </h3>
            </header>
            <br />

            <div className="row pointers-container">
              <div
                className="col-md-12  wow bounceInUp "
                data-wow-duration="1.4s"
              >
                <div className="row">
                  <div
                    className="col-md-6  wow bounceInUp "
                    data-wow-duration="1.4s"
                  >
                    <div className="box box-2 blank_service">
                      <p>
                        <div className={"row"}>
                          <div className={"col-sm-12"}>
                            <span id={"one_point"}> 1. </span>{" "}
                            {lan.en["web"]["service"][1]}
                          </div>
                        </div>
                      </p>
                    </div>
                  </div>
                  <div
                    className="col-md-6  wow bounceInUp "
                    data-wow-duration="1.4s"
                  >
                    <div className="box box-2 blank_service">
                      <p>
                        <div className={"row"}>
                          <div className={"col-sm-12"}>
                            <span id={"two_point"}> 2. </span>{" "}
                            {lan.en["web"]["service"][2]}
                          </div>
                        </div>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* ========================================  type =========================================== */}

          <section id="services" className="processing">
              <div className={"row"}>
                <div align={"center"}>
                  <br /> <br />
                  <br />
                  <h2 className="light-text"> Technologies We Use </h2>
                </div>
                <h4 className="highlighted-text" align="center">
                  Mobile Technology
                </h4>
                <br /> <br />
                    <h5 className="light-text" align="center">1.Native Application</h5>
                <div className="mobile-tech-container">
                  <div className="box ">
                    <div align="center">
                      <img
                        src={Apple}
                        alt=""
                        className="img-fluid"
                        width="100"
                      />
                    </div>{" "}
                    <br />
                    <div align="center">
                      <h4 className="tech_head">IOS</h4>
                    </div>
                  </div>
                  <div className="box ">
                    <div align="center">
                      <img
                        src={Android}
                        alt=""
                        className="img-fluid"
                        width="100"
                      />
                    </div>{" "}
                    <br />
                    <div align="center">
                      <h4 className="tech_head">Android</h4>
                    </div>
                  </div>{" "}
                  <div className="box ">
                    <div align="center">
                      <img src={Swift} alt="" className="img-fluid" width="100" />
                    </div>{" "}
                    <br />
                    <div align="center">
                      <h4 className="tech_head">Swift</h4>
                    </div>
                  </div>{" "}
                  <div className="box ">
                    <div align="center">
                      <img
                        src={Kotlin}
                        alt=""
                        className="img-fluid"
                        width="100"
                      />
                    </div>{" "}
                    <br />
                    <div align="center">
                      <h4 className="tech_head">Kotlin</h4>
                    </div>
                  </div>  
                </div>
                <h5 className="light-text mt-4" align="center">
                    2.Hybrid Applications
                  </h5>
                <div className="mobile-tech-container">
                  <div className="box ">
                    <div align="center">
                      <img
                        src={Flutter}
                        alt=""
                        className="img-fluid"
                        width="100"
                      />
                    </div>{" "}
                    <br />
                    <div align="center">
                      <h4 className="tech_head">Flutter</h4>
                    </div>
                  </div>
                  <div className="box ">
                    <div align="center">
                      <img
                        src={ReactNative}
                        alt=""
                        className="img-fluid"
                        width="100"
                      />
                    </div>{" "}
                    <br />
                    <div align="center">
                      <h4 className="tech_head">React Native</h4>
                    </div>
                  </div> 
              </div>
            </div>
          </section>


          {/* <section id="services" className="section-bg processing">
            <div className={"container"}>
              <div className={"row"}>
                <div align={"center"}>
                  <h2 className={"process_head"}> Technologies We Use </h2>
                </div>
                <br />
                <br />
                <br />
                <div className={"col-sm-12"}>
                  <div className={"table-responsive"}>
                    <table className="table ">
                      <thead></thead>

                      <tbody>
                        <tr align={"center"}>
                          <th scope="row">Mobile Technology </th>

                          <td>Ios</td>
                          <td>Swift</td>
                          <td>Flutter</td>
                          <td>React Native</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </section> */}

          <br />
          <br />

          <Footer />
        </motion.div>
        <MetaTags title={"Mobile Technology | Koderbox"} />
      </>
    );
  }
}

export default MobileDevelopment;
